<template>
  <el-container>
    <el-header class="header1" height="32">
      <el-row type="flex" justify="start">
        <div class="back">
          <Icon iconName="arrow_left_white" />
          <Icon iconName="letter_a_white" />
        </div>
        <BreadCrumbs />
        <UserProfileButton /> </el-row
    ></el-header>
    <el-header class="header2" height="32"
      ><el-row type="flex" justify="start" align="center">
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="export_blue" :label="$t('Експорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export-to-json"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("в JSON") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </el-header>
    <el-header class="header3" height="48" ref="headerPanel"
      ><el-row type="flex" justify="start">
        <div style="padding-left: 19px">
          <el-input
            :placeholder="$t('Пошук')"
            style="width: 308px"
            prefix-icon="el-icon-search"
            clearable
            v-model="filterByName"
            ref="inputFilterName"
            @keydown.esc.native="resetFilterName"
          />
        </div>
        <div style="padding-left: 19px; line-height: 48px; display: flex; align-items: center;">
          <el-dropdown trigger="click" @command="handleClick" v-if="isSmallWidth">
            <span class="el-dropdown-link">
              <TIButton :label="$t('Показувати') + ': ' + _filterBy" dropdown="true" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="show-all"
                ><span :class="{ active: showOnlyWithCustomCoeff == 'false' }">{{ $t("Все") }}</span></el-dropdown-item
              >
              <el-dropdown-item command="show-only-set"
                ><span :class="{ active: showOnlyWithCustomCoeff == 'true' }">{{ $t("Змінені користувачем") }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else>
            <el-radio-group v-model="showOnlyWithCustomCoeff" size="small">
              <el-radio-button label="false">{{ $t("Показувати все") }}</el-radio-button>
              <el-radio-button label="true">{{ $t("Показувати тільки змінені користувачем") }}</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="push" style="padding-right: 19px" v-bind:class="{ invalid: !filterByDateValid }">
          <span>{{ $t("Період") }}: </span>
          <el-date-picker v-model="filterByDateFrom" type="date" format="dd.MM.yyyy" ref="date1"> </el-date-picker>
          <span> - </span>
          <el-date-picker v-model="filterByDateTo" type="date" format="dd.MM.yyyy" ref="date2"> </el-date-picker>
        </div> </el-row
    ></el-header>
    <div class="analytics-bonus-matrix-el-container">
      <ag-grid-vue
        ref="bonusMatrixTable"
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        :defaultColDef="defaultColDef"
        :columnDefs="columns"
        :treeData="true"
        :rowData="rows"
        :context="context"
        :gridOptions="gridOptions"
        :frameworkComponents="frameworkComponents"
        :enableBrowserTooltips="true"
        rowSelection="single"
        :overlayNoRowsTemplate="noRowsToShowTemplate"
        :modules="modules"
        :localeTextFunc="gridLocale"
        :sideBar="sideBar"
        @cellDoubleClicked="onGridDoubleClick"
        :getContextMenuItems="contextMenuItems"
        @sortChanged="onGridColumnsChange"
        @columnResized="onGridColumnsChange"
        @columnMoved="onGridColumnsChange"
        @displayedColumnsChanged="onGridColumnsChange"
        :getDataPath="getDataPath"
        :groupDefaultExpanded="0"
        :autoGroupColumnDef="autoGroupColumnDef"
      >
      </ag-grid-vue>
    </div>
    <EditBonusMatrixValue @commit="onChangeBonusCoeff" bus-event="bonusMatrix.edit.value" />
    <BonusMatrixHistoryDialog bus-event="bonusMatrixTable.history" />
  </el-container>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import UserProfileButton from "@/components/UserProfileButton";
import BreadCrumbs from "@/components/BreadCrumbs";
import { bus } from "@/main";

const BonusMatrix = {
  name: "BonusMatrix",
  components: {
    AgGridVue,
    Icon,
    TIButton,
    BreadCrumbs,
    UserProfileButton,
    EditBonusMatrixValue: () => import("@/components/forms/EditBonusMatrixValue"),
    BonusMatrixHistoryDialog: () => import("@/components/forms/BonusMatrixHistory"),
  },
  beforeMount() {
    var that = this;
    this.frameworkComponents = { GridCellWithIcons: GridCellWithIcons };
    this.context = { componentParent: this };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    var state = this.$store.getters["main/userSettings"]("bonusMatrixColumnState");
    if (state) {
      this.gridOptions.columnApi.setColumnState(state.filter((col) => ["okpo", "ag-Grid-AutoColumn"].includes(col.colId)));
    }
    this.observeWidth();
  },
  beforeUnmount() {
    this.unobserveWidth();
  },
  data() {
    return {
      resizeObserverWidth: null,
      paneWidth: 1280,
      filterByName: "",
      showOnlyWithCustomCoeff: false,
      context: null,
      changeColumnsTimer: 0,
      modules: AllModules,
      frameworkComponents: null,
      gridApi: null,
      gridOptions: {
        headerHeight: 48,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: false, //!!!! Infinite loop if set to true
        getRowHeight: function(params) {
          return 32;
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      getDataPath(data) {
        return data.path;
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        filter: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        wrapText: false,
        autoHeight: false,
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        headerTooltip: $tt("Назва"),
        sort: "asc",
        field: "orgName",
        pinned: "left",
        cellRendererParams: {
          suppressCount: true,
          checkbox: false,
        },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
          if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
          return valueA > valueB ? 1 : -1;
        },
      },
      noRowsToShowTemplate: `<div><img src="${require("../assets/icons/no_data.svg")}"></div>`,
    };
  },
  methods: {
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    resetFilterName(event) {
      this.$refs.inputFilterName.clear();
    },
    onGridDoubleClick(params) {
      if (params.colDef.field == "orgName" || params.colDef.field == "okpo") return;
      var contractId = params.column.colId;
      var axiomaId = params.data.axiomaId;
      bus.$emit("bonusMatrix.edit.value.show", {
        title: $tt("Вибір коефіцієнта"),
        initModel: {
          contractId,
          axiomaId,
          coeff: params.data[contractId] ? params.data[contractId].coeff : null,
          historyCount: params.data[contractId] ? params.data[contractId].historyCount : 0,
        },
      });
    },
    onChangeBonusCoeff(data) {
      var rowNode = this.gridApi.getRowNode(data.model.axiomaId);
      if (rowNode.data[data.model.contractId] && rowNode.data[data.model.contractId].coeff == data.model.coeff) {
        bus.$emit("bonusMatrix.edit.value.hide");
        return;
      }
      var loading = this.$loading({ target: "#EditBonusMatrixValueDialog>div", text: $tt("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("analytics/saveBonusMatrixCoeff", data.model)
        .finally(() => {
          loading && loading.close();
        })
        .then((result) => {
          bus.$emit("bonusMatrix.edit.value.hide");
          this.$message.success($tt("Коефіцієнт збережено!"));
          var row = rowNode.data;
          if (row[data.model.contractId] && row[data.model.contractId].coeff) {
            row[data.model.contractId].coeff = result.coeff;
            row[data.model.contractId].historyCount = row[data.model.contractId].historyCount + 1;
          } else {
            row[data.model.contractId] = { coeff: result.coeff, historyCount: 1 };
            row.rowWithCustomCoeff = true;
          }
          rowNode.setData(row);
          this.gridApi.redrawRows({ rowNodes: [rowNode] });
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка збереження"), err.data.msg);
        });
    },
    showBonusMatrixHistory(data) {
      bus.$emit("bonusMatrixTable.history.show", {
        title: $tt("Історія редагування бонусу"),
        initModel: data,
      });
    },
    getContextIcon(icon) {
      return `<img class="svg" src="${require(`../assets/icons/${icon}.svg`)}" />`;
    },
    contextMenuItems(params) {
      var contextMenu = [];
      var that = this;
      if (
        params &&
        params.column &&
        params.column.colDef.field != "orgName" &&
        params.column.colDef.field != "okpo" &&
        params.node.data[params.column.colDef.field]
      ) {
        contextMenu.push(
          {
            name: $tt("Встановити для стовпчика"),
            icon: that.getContextIcon("apply_for_column_green"),
            action() {
              that.applyForColumn(params);
            },
          },
          {
            name: $tt("Встановити для рядка"),
            icon: that.getContextIcon("apply_for_row_green"),
            action() {
              that.applyForRow(params);
            },
          },
          "separator"
        );
      }
      contextMenu.push("copy");
      contextMenu.push("export");
      return contextMenu;
    },
    applyForColumn(params) {
      var that = this;
      var coeff = params.node.data[params.column.colDef.field].coeff;
      try {
        this.$confirm($tt("Ви дійсно бажаєте застосувати обраний коефіцієнт для всіх організацій?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("main/setGlobalLoading", $tt("Копіювання коефіцієнту..."));
            var contractId = params.column.colDef.field;
            var axiomaId = params.node.data.axiomaId;
            var idx = 0;
            this.rows
              .reduce(
                (prev, org) =>
                  prev
                    .then(() => {
                      that.$store.dispatch("main/setGlobalLoading", $tt("Копіювання коефіцієнту...") + `${++idx} із ${that.rows.length - 1}`);
                      if (org.axiomaId != axiomaId)
                        // not same row
                        return that.$store.dispatch("analytics/saveBonusMatrixCoeff", { contractId: contractId, axiomaId: org.axiomaId, coeff: coeff });
                      else return Promise.resolve();
                    })
                    .catch((err) => {
                      globalErrorMessage($tt("Помилка застосування!"), err);
                      this.$store.dispatch("main/setGlobalLoading");
                    }),
                Promise.resolve()
              )
              .then(() => {
                this.$message.success($tt("Коефіцієнт застосовано!"));
                this.$store.dispatch("main/setGlobalLoading");
              })
              .catch(() => {});
          })
          .catch(() => {});
      } catch (err) {
        globalErrorMessage($tt("Помилка застосування!"), err);
      } finally {
      }
    },
    applyForRow(params) {
      var that = this;
      var coeff = params.node.data[params.column.colDef.field].coeff;
      try {
        this.$confirm($tt("Ви дійсно бажаєте застосувати обраний коефіцієнт для всіх договорів?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("main/setGlobalLoading", $tt("Копіювання коефіцієнту..."));
            var contractId = params.column.colDef.field;
            var axiomaId = params.node.data.axiomaId;
            var idx = 0;
            this.columns
              .reduce(
                (prev, col) =>
                  prev
                    .then(() => {
                      if (col.field !== "orgName" && col.field !== "okpo" && col.field != contractId) {
                        that.$store.dispatch("main/setGlobalLoading", $tt("Копіювання коефіцієнту...") + `${++idx} із ${that.columns.length - 2}`);
                        // not same contract
                        return that.$store.dispatch("analytics/saveBonusMatrixCoeff", { contractId: col.field, axiomaId: axiomaId, coeff: coeff });
                      } else return Promise.resolve();
                    })
                    .catch((err) => {
                      globalErrorMessage($tt("Помилка застосування!"), err);
                      this.$store.dispatch("main/setGlobalLoading");
                    }),
                Promise.resolve()
              )
              .then(() => {
                var newData = this.$store.getters["analytics/bonusMatrixRow"](axiomaId);
                if (newData) {
                  params.node.setData(newData);
                  this.gridApi.redrawRows({ rowNodes: [params.node] });
                }
                this.$message.success($tt("Коефіцієнт застосовано!"));
                this.$store.dispatch("main/setGlobalLoading");
              })
              .catch(() => {});
          })
          .catch(() => {});
      } catch (err) {
        globalErrorMessage($tt("Помилка застосування!"), err);
      } finally {
      }
    },
    handleClick(command) {
      switch (command) {
        case "export-to-json": {
          this.exporToJSON();
          break;
        }
        case "export-struct-to-json": {
          break;
        }
        case "show-all": {
          this.showOnlyWithCustomCoeff = "false";
          break;
        }
        case "show-only-set": {
          this.showOnlyWithCustomCoeff = "true";
          break;
        }
        default:
          break;
      }
    },
    onGridColumnsChange() {
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(this.changeColumnsTimer);
        var state = this.gridOptions.columnApi.getColumnState().filter((col) => ["okpo", "ag-Grid-AutoColumn"].includes(col.colId));
        this.$store.dispatch("main/updateUserSettings", { bonusMatrixColumnState: state });
      }, 500);
    },
    exporToJSON() {
      var dt = new Date()
        .toLocaleString()
        .split(",")
        .join("-");
      this.saveDataToJSON({ dataType: "bonusMatrixCoeff", data: this.$store.getters["analytics/bonusMatrixCoeff"]() }, "bonusMatrixCoeff_" + dt + ".json");
    },
    columnValueGetter(params) {
      if (!params.data) return "-";
      if (params.colDef.field == "orgName") return params.data.orgName;
      if (params.colDef.field == "okpo") return params.data.okpo;
      if (params.data[params.colDef.field]) {
        return params.data[params.colDef.field].coeff; // + " !";
      }
      return "-";
    },
    observeWidth() {
      var that = this;
      if (!this.resizeObserverWidth) {
        if (that.$refs.headerPanel) {
          this.resizeObserverWidth = new ResizeObserver(function() {
            if (that.$refs.headerPanel && that.$refs.headerPanel.$el) that.paneWidth = that.$refs.headerPanel.$el.clientWidth;
          });
          if (this.$refs.headerPanel) this.resizeObserverWidth.observe(this.$refs.headerPanel.$el);
        }
      }
    },
    unobserveWidth() {
      this.resizeObserverWidth && this.$refs.headerPanel && this.resizeObserverWidth.unobserve(this.$refs.headerPanel.$el);
      this.resizeObserverWidth = null;
      this.paneWidth = 1280;
    },
  },
  computed: {
    columns() {
      var columns = this.$store.getters["analytics/bonusMatrixColumns"]({
        byName: this.filterByName,
        byDateFrom: this.filterByDateFrom,
        byDateTo: this.filterByDateTo,
        onlyWithCustomCoeff: this.showOnlyWithCustomCoeff == "true",
      });
      columns.forEach((col) => {
        col.valueGetter = this.columnValueGetter;
        //col.cellClassRules = {};
      });
      return columns;
    },
    rows() {
      return this.$store.getters["analytics/bonusMatrixRows"]({
        byName: this.filterByName,
        byDateFrom: this.filterByDateFrom,
        byDateTo: this.filterByDateTo,
        onlyWithCustomCoeff: this.showOnlyWithCustomCoeff == "true",
      });
    },
    filterByDateValid() {
      if (this.filterByDateFrom && this.filterByDateTo) {
        if (Date.parse(this.filterByDateFrom) > Date.parse(this.filterByDateTo)) {
          return false;
        } else return true;
      } else return true;
    },
    filterByDateFrom: {
      get() {
        return this.$store.getters["main/getFilterByDateFrom"]();
      },
      set(value) {
        this.$store.dispatch("main/setFilterByDateFrom", value);
      },
    },
    filterByDateTo: {
      get() {
        return this.$store.getters["main/getFilterByDateTo"]();
      },
      set(value) {
        this.$store.dispatch("main/setFilterByDateTo", value);
      },
    },
    isSmallWidth() {
      return this.paneWidth < 1280;
    },
    _filterBy() {
      if (this.showOnlyWithCustomCoeff == "false") return $tt("Все");
      else return $tt("Змінені користувачем");
    },
  },
};

export default BonusMatrix;
</script>
<style lang="scss">
.el-container .analytics-bonus-matrix-el-container {
  height: calc(100vh - 48px - 48px - 17px);
}

.el-container .analytics-bonus-matrix-el-container .ag-cell {
  border-right-color: #ddd;
  border-right-width: 1px;
  border-right-style: solid;
}

.cell-user-value {
  color: black;
}

.cell-default-value {
  color: gray;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}
</style>
